<template lang="pug">
v-card(v-if="Object.keys(sailorDocument).length").pa-4
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t(`${Object.keys(sailorDocument.behavior)[0]}-${type}`, { number: sailorDocument.ntz_number }).toUpperCase() }}
      span(v-if="checkAccess('backOffice')").ml-1 (ID: {{ sailorDocument.id }})
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-card-text
    v-divider
    Info(v-if="sailorDocument.behavior.viewInfoBlock && !$route.query.viewEditBlock" :sailorDocument="sailorDocument")
    FormEdit(v-else-if="sailorDocument.behavior.viewEditBlock || $route.query.viewEditBlock" :sailorDocument="sailorDocument" actionType="edit")
    EditStatus(v-else-if="sailorDocument.behavior.viewEditStatusBlock" :sailorDocument="sailorDocument")
    ViewPhotoList(
      v-else-if="sailorDocument.behavior?.viewFilesBlock"
      :files="sailorDocument.files"
      :sailorDocument="sailorDocument"
      :documentType="type"
      :deleteFile="removeFileCertificate"
      :editFile="updateFileCertificateETI"
      isShowEdit
    )
v-progress-linear(v-else indeterminate)
</template>

<script>
import { viewDetailedComponent, back, deleteConfirmation, copyToClipboard } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapGetters, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorCertificationDocument',
  components: {
    Info: () => import('@/views/Sailor/SailorCertification/Info.vue'),
    FormEdit: () => import('@/views/Sailor/SailorCertification/Form.vue'),
    EditStatus: () => import('@/views/Sailor/SailorCertification/EditStatus.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewFile/ViewFile.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons')
  },
  data () {
    return {
      type: 'certification',
      viewDetailedComponent,
      deleteConfirmation,
      checkAccess,
      back,
      controlButtons: [
        {
          id: 8,
          name: 'mdi-content-copy',
          action: () => copyToClipboard(location.href),
          color: '#42627e',
          tooltip: 'documentIconControl.copyLink'
        },
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewInfoBlock'),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.info'
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditBlock'),
          checkAccess: () => checkAccess('certification', 'edit', this.sailorDocument) && !this.sailorDocument.statement,
          color: '#42627e',
          tooltip: 'documentIconControl.edit'
        },
        {
          id: 3,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewFilesBlock'),
          checkAccess: () => checkAccess('certification', 'view_file'),
          color: '#42627e',
          tooltip: 'documentIconControl.files'
        },
        {
          id: 6,
          name: 'mdi-sync',
          action: () => viewDetailedComponent(this.sailorDocument, 'viewEditStatusBlock'),
          checkAccess: () => checkAccess('certification', 'editStatus', this.sailorDocument),
          color: '#42627e',
          tooltip: 'documentIconControl.editStatus'
        },
        {
          id: 4,
          name: 'mdi-trash-can-outline',
          action: () => this.deleteDocument(),
          checkAccess: () => checkAccess('certification', 'delete', this.sailorDocument) && !this.sailorDocument.statement,
          color: '#F73E07',
          tooltip: 'documentIconControl.delete'
        },
        {
          id: 5,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['sailorDocumentByID']),
    ...mapState({
      isLoading: state => state.sailor.isLoading,
      sailorDocument: state => state.sailor.certificateEtiById
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getCertificateEtiById', 'removeCertificate', 'removeFileCertificate',
      'updateFileCertificateETI']),
    async getData () {
      await this.getCertificateEtiById({ ...this.$route.params })
    },
    deleteDocument () {
      deleteConfirmation().then(confirmation => {
        if (confirmation) {
          this.removeCertificate({ ...this.$route.params }).then(response => {
            if (SUCCESS_CODE.includes(response?.code)) {
              this.$notification.success('deletedDocument')
              back('certification-certificates')
            } else this.$notification.error('cantDeleteDoc')
          })
        }
      })
    }
  }
}

</script>
